.quote {
  h2 {
    font-size: 2rem;
    line-height: 50px;

    &:before {
      content: '';
      border-top: 4px solid $primary;
      width: 15rem;
      padding-bottom: 2.5rem;
      margin: 0 auto;
      display: block;

      @include media-breakpoint-down(sm){
        width: 8rem;
      }
    }
  }
}

.customer-quote {
  img {
    margin: 0 auto;
    max-width: 250px;
    height: auto !important;
  }
}
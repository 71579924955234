@if $use-rotateOutDownLeft == true {

	@-webkit-keyframes rotateOutDownLeft {
		0% {
			-webkit-transform-origin: left bottom;
			-webkit-transform: rotate(0);
			opacity: 1;
		}

		100% {
			-webkit-transform-origin: left bottom;
			-webkit-transform: rotate($rotate-degrees);
			opacity: 0;
		}
	}

	@keyframes rotateOutDownLeft {
		0% {
			transform-origin: left bottom;
			transform: rotate(0);
			opacity: 1;
		}

		100% {
			transform-origin: left bottom;
			transform: rotate($rotate-degrees);
			opacity: 0;
		}
	}

	.rotateOutDownLeft {
		@include animate-prefixer(animation-name, rotateOutDownLeft);
	}
}

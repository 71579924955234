.text-shadow
{
  @include text-shadow(1px);

  &-inverse
  {
    @include text-shadow(1px, #fff);
  }

  &-large {
    @include text-shadow(3px);

    &-inverse
    {
      @include text-shadow(3px, #fff);
    }
  }
}
body {
  font-size: 1.125rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 1.375rem 0 1.375rem 0;
  color: $gray-base;
}

h1, .h1 {
  &:after {
    position: absolute;
    padding-left: 5px;
    content: url("/theme/images/font-icon/eye-icon-lg.svg");
  }

  &.no-after {
    &::after {
      display: none;
    }
  }
}

h2, .h2 {
  &:after {
    position: absolute;
    padding-left: 5px;
    content: url("/theme/images/font-icon/eye-icon-md.svg");
  }

  &.no-after {
    &::after {
      display: none;
    }
  }
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  &:after {
    position: absolute;
    padding-left: 5px;
    content: url("/theme/images/font-icon/eye-icon-sm.svg");
  }

  &.no-after {
    &::after {
      display: none;
    }
  }
}


@include media-breakpoint-down(md) {
  h1, .h1 {
    font-size: 2.5rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.2rem;
  }
}

a {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

p {
  font-size: 1.125rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  &.large {
    font-size: 1.25rem;

    @include media-breakpoint-down(md){
      font-size: 1rem;
    }
  }
}

h2, .h1 {
  font-size: 3.45rem;

  @include media-breakpoint-down(md){
    font-size: 2rem;
  }
}

hr {
  width: 100%;
}

footer hr {
  //margin-top: 0.75rem;
  margin-bottom: .5rem;
  border-top: 1px solid $white;
}
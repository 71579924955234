.video-section {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);

  }

  .container {
    position: relative;
    z-index: 2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  .playpause {
    background-image: url("/theme/images/play-button.svg");
    background-repeat: no-repeat;
    width: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: contain;
    background-position: center;
    z-index: 9999;
  }

  &:hover {
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    height: auto;
    min-height: unset;
  }
}


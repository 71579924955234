.side-label {
  position: absolute;
  background: $primary;
  top: 200px; /* was 30%, maar bij hoge content ging dit mis, ook 30vh was bij grote schermen niet toereikend */
  left: 0;
  z-index: 10;
  padding: 1.5rem;
  writing-mode: vertical-lr;


  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    &:after {
      display: none;
    }
  }

  &-content {
    display: block;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}
.client {
  &-logo {
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }
  }
}
@include media-breakpoint-up(md) {
  .navbar,
  .container-fluid {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }
}

main {
  position: relative;
}
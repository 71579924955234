@if $use-fadeInLeftBig == true {

	@-webkit-keyframes fadeInLeftBig {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-$base-distance-big * 2);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@keyframes fadeInLeftBig {
		0% {
			opacity: 0;
			transform: translateX(-$base-distance-big * 2);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInLeftBig {
		@include animate-prefixer(animation-name, fadeInLeftBig);
	}

}

.accordion {
  &-item {
    padding: 1.5rem 0;
    border-bottom: 1px solid #707070;

    &:hover {
      cursor: pointer;

      a {
        color: $primary;
      }
    }

    i {
      color: $primary;
      font-size: 1rem;
    }

    @include media-breakpoint-down(md) {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &-button {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    color: $gray-900;
    display: flex;
    justify-content: space-between;

    &:hover {
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      color: $primary;
    }
  }

  // image styling
  &-image {
    min-height: 450px;
    font-size: 1.2rem;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      &:after {
        display: none;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
                      to bottom,
                      rgba(0, 0, 0, 0) 0%,
                      rgba(0, 0, 0, 0.7) 100%
      );
    }

    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }

  &-caption {
    width: 50%;
    position: absolute;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $white;
    bottom: 1rem !important;
    text-align: left !important;
    left: 3rem !important;
    right: 0 !important;

    &-title {
      display: block;
      margin-bottom: 1rem;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(md) {
      width: 80%;
      left: 2rem !important;
    }
  }
}
@if $use-pulse == true {

	// originally authored by Nick Pettit - https://github.com/nickpettit/glide

	@-webkit-keyframes pulse {
		0% { -webkit-transform: scale(1); }
		50% { -webkit-transform: scale(1.1); }
		100% { -webkit-transform: scale(1); }
	}

	@keyframes pulse {
		0% { transform: scale(1); }
		50% { transform: scale(1.1); }
		100% { transform: scale(1); }
	}

	.pulse {
		@include animate-prefixer(animation-name, pulse);
	}

}

@if $use-rotateInUpLeft == true {

	@-webkit-keyframes rotateInUpLeft {
		0% {
			-webkit-transform-origin: left bottom;
			-webkit-transform: rotate($rotate-degrees);
			opacity: 0;
		}

		100% {
			-webkit-transform-origin: left bottom;
			-webkit-transform: rotate(0);
			opacity: 1;
		}
	}

	@keyframes rotateInUpLeft {
		0% {
			transform-origin: left bottom;
			transform: rotate($rotate-degrees);
			opacity: 0;
		}

		100% {
			transform-origin: left bottom;
			transform: rotate(0);
			opacity: 1;
		}
	}

	.rotateInUpLeft {
		@include animate-prefixer(animation-name, rotateInUpLeft);
	}

}

// number of cards per line for each breakpoint
$cards-per-line: (
        xs: 1,
        sm: 1,
        md: 2,
        lg: 4,
        xl: 4
);

$cards-per-line-personeel: (
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 3
);

$cards-per-line-personeel-home: (
        xs: 1,
        sm: 2,
        md: 2,
        lg: 4,
        xl: 4
);

$grid-gutter-width: 0px;
$grid-gutter-width-personeel: 30px;

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card {
      flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
    }
  }
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card.personeel {
      flex: 0 0 calc(#{100/map-get($cards-per-line-personeel, $name)}% - #{$grid-gutter-width-personeel});
    }
  }
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card.personeel-home {
      flex: 0 0 calc(#{100/map-get($cards-per-line-personeel-home, $name)}% - #{$grid-gutter-width});
    }
  }
}

.card {
  margin-bottom: $grid-gutter-width !important;
  border: none;
  margin-left: 0!important;
  margin-right: 0!important;

  &-title {
    color: #FFF;
    font-size: 2rem;
    line-height: 1;
    margin: 0;

    &:after {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  &-text {
    font-size: 1.2rem;
    color: #FFF;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    &-lg {
      font-size: 2.5rem;

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }
  }

  img {
    width: 100%;
  }

  &.personeel {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-bottom: 30px !important;
  }
}

.work {
  &-image {
    color: $white;
    font-size: 1.2rem;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      &:after {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }

  &-caption {
    position: absolute;
    z-index: 10;
    text-align: left !important;
    bottom: 1rem !important;
    left: 3rem !important;
    right: 10rem !important;

    &-title {
      display: block;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
      font-size: 2rem;
      line-height: 1;
    }

    @include media-breakpoint-down(lg) {
      width: 80%;
      left: 2rem !important;
    }
  }
}
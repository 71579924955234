.usp-block{
  ul {
    padding:0;

    li {
      list-style: none;
    }
  }

  .usp {
    &-item {
      padding: 1.5rem 0;
      border-bottom: 1px solid #707070;

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        &:after {
          display: none;
        }
      }
    }
  }
}
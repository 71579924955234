@if $use-slideOutDown == true {

	@-webkit-keyframes slideOutDown {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(0, 100%, 0);
		}
	}

	@keyframes slideOutDown {
		0% {
			transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(0, 100%, 0);
		}
	}

	.slideOutDown {
		@include animate-prefixer(animation-name, slideOutDown);
	}

}

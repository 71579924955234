.slick {
  &-slide img {
    height: 750px;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  &-btn {
    background-color: $primary;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1.25rem);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12);
    width: 3.75rem;
    height: 3.75rem;
    color: $white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;

    &:hover {
      background-color: darken($primary, 20%);
    }

    &-prev {
      left: 2rem;
    }

    &-next {
      right: 17rem;

      @include media-breakpoint-down(md) {
        right: 6rem;
      }
      @include media-breakpoint-down(sm) {
        right: 1rem;
      }
    }
  }

  &-disabled {
    transition: .5s;
    opacity: 0;
    pointer-events: none;
  }
}

.slider-image {
  padding-right: 80px;

  @include media-breakpoint-down(sm) {
    padding-right: 30px;
  }
}

// temp wow.js flash fix
.wow {
  visibility: hidden;
  opacity: 0;
}
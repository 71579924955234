.header {
  position: relative;

  &-absolute-case {
    position: absolute;
    bottom: -5rem;
    right: 0;

    @include media-breakpoint-down(md) {
      position: unset;
      margin-top: -4rem;
    }
  }

  .overlay-text {
    align-self: flex-start;
    transform: translate(20%, 20%);
    background-color: #ffffff;
    padding: 20px;
    z-index: 999;

    @include media-breakpoint-down(sm) {
      transform: translate(0, 0);
    }
  }
}
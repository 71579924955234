button {
  &:focus {
    outline: none;
  }
}

.icon-bar {
  width: 1.7rem;
  height: 3px;
  background-color: $gray-800;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
}

.navbar {
  background: $white;
  z-index: 999;

  &-brand {
    img {
      max-width: 250px;
      width: 100%;

      @include media-breakpoint-down(md) {
        max-width: 180px;
      }
    }
  }

  &-toggler {
    border: none;
    background: transparent !important;

    .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }

    &.collapsed {
      .top-bar {
        transform: rotate(0);
      }

      .middle-bar {
        opacity: 1;
      }

      .bottom-bar {
        transform: rotate(0);
      }
    }
  }

  &-nav {
    .nav {
      &-link {
        font-weight: normal;
        font-size: 1.25rem;
        font-family: $font-family-base;

        /* Fix for the number of menu-items, change if needed */
        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1200px) {
          padding-left: 0.85rem !important;
          padding-right: 0.85rem !important;
        }
      }

      &-item {
        &.active {
          .nav-link {
            display: flex;
            align-items: center;

            &:before {
              content: "\f061";
              color: $primary;
              font-family: "Font Awesome 5 Free";
              display: inline-block;
              width: 25px;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
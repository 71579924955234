@if $use-bounceIn == true {

	@-webkit-keyframes bounceIn {
		0% {
			opacity: 0;
			-webkit-transform: scale(0.3);
		}

		50% {
			opacity: 1;
			-webkit-transform: scale(1.05);
		}

		70% {
			-webkit-transform: scale(0.9);
		}

		100% {
			-webkit-transform: scale(1);
		}
	}

	@keyframes bounceIn {
		0% {
			opacity: 0;
			transform: scale(0.3);
		}

		50% {
			opacity: 1;
			transform: scale(1.05);
		}

		70% {
			transform: scale(0.9);
		}

		100% {
			transform: scale(1);
		}
	}

	.bounceIn {
		@include animate-prefixer(animation-name, bounceIn);
	}

}

ul {
  &.arrow-list {
    li {
      list-style: none;

      &:before {
        content: '';
        display: inline-block;
        height: 1rem;
        width: 1rem;
        background-image: url('/theme/images/font-icon/arrow.png');
        background-size: contain;
        background-repeat: no-repeat;
        padding-right: 2rem;
      }
    }
  }
}

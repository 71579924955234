@if $use-rotateInDownRight == true {

	@-webkit-keyframes rotateInDownRight {
		0% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate($rotate-degrees);
			opacity: 0;
		}

		100% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate(0);
			opacity: 1;
		}
	}

	@keyframes rotateInDownRight {
		0% {
			transform-origin: right bottom;
			transform: rotate($rotate-degrees);
			opacity: 0;
		}

		100% {
			transform-origin: right bottom;
			transform: rotate(0);
			opacity: 1;
		}
	}

	.rotateInDownRight {
		@include animate-prefixer(animation-name, rotateInDownRight);
	}

}

@if $use-flipOutY == true {

	@-webkit-keyframes flipOutY {
		0% {
			-webkit-transform: perspective($flipper-perspective) rotateY(0deg);
			opacity: 1;
		}
		100% {
			-webkit-transform: perspective($flipper-perspective) rotateY($flipper-degrees-max);
			opacity: 0;
		}
	}

	@keyframes flipOutY {
		0% {
			transform: perspective($flipper-perspective) rotateY(0deg);
			opacity: 1;
		}
		100% {
			transform: perspective($flipper-perspective) rotateY($flipper-degrees-max);
			opacity: 0;
		}
	}

	.flipOutY {
		@include animate-prefixer(backface-visibility, visible !important);
		@include animate-prefixer(animation-name, flipOutY);
	}
}

.vacature {
  &-item {
    padding: 1.5rem 0;
    border-bottom: 1px solid #707070;

    &:hover {
      cursor: pointer;

      a {
        color: $primary;
      }
    }

    i {
      color: $primary;
      font-size: 1rem;
    }

    @include media-breakpoint-down(md) {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &-button {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    color: $gray-900;
    display: flex;
    justify-content: space-between;

    &:hover {
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      color: $primary;
    }
  }
}
.carousel {
  &-item {
    height: 600px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }

    @include media-breakpoint-down(md) {
      height: 350px;
    }

    @include media-breakpoint-up(xl) {
      height:800px; 
    }
  }

  &-caption {
    bottom: 50px !important;
    text-align: left !important;
    left: 0 !important;
    right: 0 !important;
  }
}
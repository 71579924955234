.absolute-eye {
  position: absolute;
  z-index: 9999;
  max-width: 20%;
  overflow: hidden;

  img {
    position: relative;
  }

  &.left {
    img {
      left: -76.5%;
    }
  }

  &.right {
    right: 0;

    img {
      right: -50%;
    }
  }
}
$animate-name: 'zoomOutDown';

@mixin zoomOutDown($prefix: '') {
	40% {
		opacity: 1;
		#{$prefix}transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		#{$prefix}animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	100% {
		opacity: 0;
		#{$prefix}transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
		#{$prefix}transform-origin: center bottom;
		#{$prefix}animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@if $use-zoomOutDown == true {

	@-webkit-keyframes #{$animate-name} {
		@include zoomOutDown('-webkit-');
	}

	@keyframes #{$animate-name} {
		@include zoomOutDown();
	}

	.#{$animate-name} {
		@include animate-prefixer(animation-name, $animate-name);
	}

}

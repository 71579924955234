$border-color-light: #f2f2f2;
$border-color-medium: #cccccc;

$result-graph-border-color: $border-color-medium;
$result-graph-background-color: $border-color-light;
$result-graph-color1: $primary;
$result-graph-color2: $border-color-light;
$graph-color1: $primary;
$graph-color2: $border-color-medium;

img.rapport_pie {
  max-width: 100%;
  height: auto !important;
}

.gps_result-table {
  display: table;
  table-layout: fixed;
  width: 100%;

  .gps_result-table-row {
    display: table-row;
    width: 100%;

    .gps_result-table-cell {
      display: table-cell;
      padding: 0.2em 0;
      vertical-align: top;

      &.gps_result-table-position {
        padding-right: 10px;
        width: 1.8em;

        span {
          display: block;
          font-weight: bold;

          &:after {
            content: ".";
          }
        }
      }

      &.gps_result-table-content {
        a {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        div {
          font-size: 0.9em;
          word-spacing: 0.5em;
          color: mix($body-color, #ffffff, 50%);

          span {
            color: mix($body-color, #ffffff, 75%);
            word-spacing: 0;
          }

          &.gps_result-title {
            font-size: 1.1em;
            word-spacing: inherit;
            color: $body-color;
          }
        }
      }

      &.gps_result-table-graph {
        width: 250px;
        padding-left: 0.85em;

        div.enabled {
          @include box-shadow(1px 1px 4px 0 $result-graph-border-color);
          @include border-radius(5px);
          padding: 1px;
          border: 1px solid $result-graph-border-color;

          span {
            @include gradient-x($start-color: $result-graph-color1, $end-color: #ffffff, $end-percent: 80%);
            @include border-radius(4px);
            display: block;
            height: 18px;
          }
        }
      }

      &.gps_result-table-icon {
        padding-left: 0.85em;
        width: 16px;
      }
    }
  }
}

@media (max-width: 599px) {
  .gps_result-beroepen {
    display: block;

    .gps_result-table-row {
      display: block;

      .gps_result-table-cell {
        display: block;

        &.gps_result-table-position {
          float: left;
        }

        &.gps_result-table-graph {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .gps_result-beroepen {
    .gps_result-table-row {
      .gps_result-table-cell {
        &.gps_result-table-graph {
          width: 35%;
        }
      }
    }
  }
}

.gps_rapport-persoonlijkheid-relatief,
.gps_rapport-persoonlijkheid-succesfactoren {
  .gps_result-table-cell {
    position: relative;
    padding: 0 !important;
    border: solid #ffffff;
    border-width: 1px 0;
    font-size: 0;
    line-height: 0;

    &.gps_result-table-content {
      padding-right: 10px;
      font-size: 16px;
      line-height: 20px;
      vertical-align: middle;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: block;
      width: 100%;
      font-size: 16px;
      line-height: 28px;
      color: #ffffff;
    }

    img {
      width: 100% !important;
      height: 28px !important;
    }

    &.gps_rapport-score-plus {
      background: $graph-color1;

      span {
        text-align: right;
        letter-spacing: 5px;
      }
    }

    &.gps_rapport-score-min {
      background: $graph-color2;

      span {
        text-indent: 5px;
        color: $body-color;
      }
    }
  }
}

.gps_rapport-persoonlijkheid-relatief .gps_rapport-persoonlijkheid-relatief-header,
.gps_rapport-persoonlijkheid-succesfactoren .gps_rapport-persoonlijkheid-succesfactoren-header {
  .gps_result-table-cell {
    font-size: 16px;
    line-height: 20px;
  }
}

.gps_rapport-score {
  background: #93b83e;
}

.gps_rapport-neutraal {
  background: $result-graph-background-color;
}

.gps_rapport-graph {
  margin-top: 1em;

  canvas {
    width: 100%;
    max-width: 556px;
    height: auto;
    background: #ffffff;
    border: 1px solid $border-color-medium;
  }

  .gps_rapport-graph-img {
    text-align: center;

    img {
      width: 100%;
      max-width: 545px;
      height: auto;
    }
  }
}

body.gps_print {
  background: #ffffff;
  color: $body-color;
}

.gps_print-header {
  margin-top: 2em;
  border-top: solid 1px #666666;
  padding-top: 2em;
}

.voorblad-logo,
.voorblad-photo {
  display: none;
}

body,
div {
  position: relative;
  -webkit-print-color-adjust: exact;
}

.page {
  page-break-after: always;
  page-break-inside: avoid;
  -webkit-region-break-inside: avoid;
  padding: 30px;
}

.gps_print-container {
  max-width: 768px;
  margin: 0 auto;
}

.progress {
  height: 18px;
  margin-top: 2px;
  margin-bottom: 12px;

  .progress-bar-small {
    line-height: 18px;
    font-size: 11px;
  }
}

@media print {
  .gps_print-container {
    max-width: none;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  canvas,
  img {
    page-break-inside: avoid;
    -webkit-region-break-inside: avoid;
    position: relative;
  }

  .page {
    padding: 0;
  }

  .gps_print-header {
    display: block;
    position: relative;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  .voorblad {
    h1 {
      text-align: center;
      padding: 2em 0;
      font-size: 3em;

      strong {
        display: block;
        padding-bottom: 1em;
        font-size: 0.75em;
      }
    }

    .voorblad-logo {
      text-align: center;
      display: block;
      padding-bottom: 2em;
    }

    .voorblad-info {
      text-align: center;
    }

    .voorblad-photo {
      display: block;
      text-align: center;
      padding-bottom: 2em;

      .voorblad-photo-image {
        display: inline-block;
        border: solid 2px #cccccc;

        img {
          border: solid 5px #ffffff;
          max-width: 400px;
          max-height: 300px;
        }
      }
    }
  }
}

// Vragen / antwoorden
.gps_antwoord-container {
  margin: 1em 0;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.gps_antwoord-image {
  background: #ffffff;
  padding: 10px;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.15);
  max-width: 75%;

  img {
    width: 100%;
  }
}

.gps_antwoord-tekst {
  margin-top: 10px;
  min-height: 2em;
}

.gps_antwoord-keuzes {
}
label {
  margin-bottom: 0;
}
label.gps_antwoord-keuze {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;

  input,
  span {
    display: none;
  }
}

.gps_antwoord-keuze {
  font-size: 0;
  line-height: 0;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 32px;
    height: 32px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background: url("/images/carrieregps/plusmin.png") no-repeat;
  }
}

.gps_antwoord-ja {
  &:after {
    background-position: 0 0;
  }

  &:hover:after {
    background-position: -32px 0;
  }

  &.gps_antwoord-checked:after {
    background-position: -64px 0 !important;
  }
}

.gps_antwoord-nee {
  &:after {
    background-position: 0 -32px;
  }

  &:hover:after {
    background-position: -32px -32px;
  }

  &.gps_antwoord-checked:after {
    background-position: -64px -32px !important;
  }
}

.gps{
  &_button {
    display: flex;
    justify-content: flex-end;
  }
}
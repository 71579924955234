@if $use-rotateOutUpRight == true {
	@-webkit-keyframes rotateOutUpRight {
		0% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate(0);
			opacity: 1;
		}

		100% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate($rotate-degrees);
			opacity: 0;
		}
	}

	@keyframes rotateOutUpRight {
		0% {
			transform-origin: right bottom;
			transform: rotate(0);
			opacity: 1;
		}

		100% {
			transform-origin: right bottom;
			transform: rotate($rotate-degrees);
			opacity: 0;
		}
	}

	.rotateOutUpRight {
		@include animate-prefixer(animation-name, rotateOutUpRight);
	}

}

$animate-name: 'zoomInUp';

@mixin zoomInUp($prefix: '') {
	0% {
		opacity: 0;
		#{$prefix}transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		#{$prefix}animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		#{$prefix}transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		#{$prefix}animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@if $use-zoomInUp == true {

	@-webkit-keyframes #{$animate-name} {
		@include zoomInUp('-webkit-');
	}

	@keyframes #{$animate-name} {
		@include zoomInUp();
	}

	.#{$animate-name} {
		@include animate-prefixer(animation-name, $animate-name);
	}

}

.bg {
  &-darker {
    background-color: #333333;
  }
}

// pull helper

.pull {

  &-top {
    margin-top: -50px;

    &-large {
      margin-top: -250px;

      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }
  }

  &-down {
    margin-bottom: -50px;

    &-large {
      margin-bottom: -250px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}
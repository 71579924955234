.footer {
  &-top {
    position: relative;

    &-logo {
      top: 50%;
      width: 80px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }

  &-middle {
    &-logo{
      max-width: 200px;
    }
  }

  &-nav {
    font-weight: normal;
    font-family: $font-family-base;

    li {
      a {
        font-weight: normal;
        font-size: 1rem;
        color: $white;

        @include media-breakpoint-down(sm){
          font-size: .9rem;
        }
      }
    }

    .copyright {
      color:  $gray-500;
      font-family: $font-family-base;
    }

    &-top {
    font-size: 1.2rem;

      i {
        font-size: 1.5rem;

        @include media-breakpoint-down(sm){
          font-size: 1rem;
        }
      }

      @include media-breakpoint-down(sm){
        font-size: 1rem;
      }
    }

    &-bottom {
      margin-bottom: 0;
    }
  }
}
// variables
$anim-speed: 0.3s;

// main styles
.tiles {
  font-size: 0;
  text-align: center;

  .tile {
    display: inline-block;
    margin: 10px;
    text-align: left;
    opacity: .99;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);

    &:before {
      content: '';
      background: linear-gradient(
                      to bottom,
                      rgba(0, 0, 0, 0) 0%,
                      rgba(0, 0, 0, 0.7) 100%
      );
      width: 100%;
      height: 50%;
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      transition-property: top, opacity;
      transition-duration: $anim-speed;
    }

    img {
      display: block;
      max-width: 100%;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .details {
      font-size: 1rem;
      padding: 1.25rem;
      color: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;

      span {
        display: block;
        opacity: 0;
        position: relative;
        top: 6.25rem;
        transition-property: top, opacity;
        transition-duration: $anim-speed;
        transition-delay: 0s;
      }

      .title {
        line-height: $line-height-base;
        font-weight: $font-weight-bold;
        font-size: 2rem;
      }

      .info {
        line-height: $line-height-base;
        margin-top: 5px;
        font-size: 1rem;
        font-weight: $font-weight-normal;
      }
    }

    &:focus,
    &:hover {

      &:before,
      span {
        opacity: 1;
      }

      &:before {
        top: 50%;
      }

      span {
        top: 0;
      }

      .title {
        transition-delay: 0.15s;
      }

      .info {
        transition-delay: 0.25s;
      }
    }
  }
}
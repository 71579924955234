@if $use-slideInDown == true {

	@-webkit-keyframes slideInDown {
		0% {
			-webkit-transform: translate3d(0, -100%, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(0, 0, 0);
		}
	}

	@keyframes slideInDown {
		0% {
			transform: translate3d(0, -100%, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(0, 0, 0);
		}
	}

	.slideInDown {
		@include animate-prefixer(animation-name, slideInDown);
	}

}
